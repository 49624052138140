import React, { FC, useContext } from 'react';
import { ToastContainer } from 'react-toastify';
import { withRouterHooks } from '../../routes/router.hooks';
import { useEnterKeySubmit } from './hooks/useEnterKeySubmit';
import { useInitializeSession } from './hooks/useInitializeSession';
import LoginForm from './components/form/Page.login.form';
import LoginFooter from './components/footer/Page.login.footer';
import './styles.css';
import { LoginContext } from './context/Login.context';
import ResetDialog from './components/reset/token/Dialog.reset.token';
import ResetDialogPassword from './components/reset/password/Dialog.reset.passord';

const LoginPage: FC<any> = () => {

  const {
    dbConnected,
    setResetDialogOpen
  } = useContext(LoginContext);

  useInitializeSession();
  useEnterKeySubmit();

  return (
    <div className='login-page'>
      <div className='login-container'>
        <div className='login-logo'>
          <img src='img/logo.svg' alt='Logo' style={{ maxWidth: '250px' }} />
        </div>
        <div className={dbConnected ? 'login-form-container' : 'login-form-container-deactivated'}>
          <LoginForm />
          <LoginFooter />
        </div>
        <div className='link-container'>
          <span className='link-text' onClick={() => setResetDialogOpen(true)}>
    Forgot your password ?
          </span>
        </div>
      </div>
      <ToastContainer />
      <ResetDialog/>
      <ResetDialogPassword/>
    </div>
  );
};

export default withRouterHooks(LoginPage);
