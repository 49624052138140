// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.e-dialog {
    max-width: calc(100vw - 100px) !important;
}

.e-dlg-content .e-content {
    max-height: calc(100vh - 340px) !important;
}

.ui.form {
    padding: 0 30px !important;
}

.ui.form>.form-container {
    border: none !important;
}

.ui.form>.form-container>.form-content {
    margin: 0 !important;
}

.e-control.e-dialog.form>.e-dlg-header-content {
    padding-bottom: 0 !important;
}

.e-control.e-dialog.form>.e-dlg-content {
    display: none !important;
}

.modal-close-icon {
    cursor: pointer !important;
    float: right !important;
}

.ui.modal>.header {
    border: none !important;
}

.e-footer-content {
    display: flex !important;
    justify-content: flex-end !important;
}

.modal-dialog-btn {
    background-color: var(--primaryColor) !important;
    border: 1px solid #D3D3D3 !important;
    border-radius: 12px !important;
    box-shadow: none !important;
    color: var(--primaryContrast) !important;
}

.modal-dialog-btn:hover,
.modal-dialog-btn:focus,
.modal-dialog-btn:active {
    background-color: color-mix(in srgb, var(--primaryColor) 80%, black 20%) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/modals/styles.css"],"names":[],"mappings":"AAAA;IACI,yCAAyC;AAC7C;;AAEA;IACI,0CAA0C;AAC9C;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,0BAA0B;IAC1B,uBAAuB;AAC3B;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,wBAAwB;IACxB,oCAAoC;AACxC;;AAEA;IACI,gDAAgD;IAChD,oCAAoC;IACpC,8BAA8B;IAC9B,2BAA2B;IAC3B,wCAAwC;AAC5C;;AAEA;;;IAGI,mFAAmF;AACvF","sourcesContent":[".e-dialog {\n    max-width: calc(100vw - 100px) !important;\n}\n\n.e-dlg-content .e-content {\n    max-height: calc(100vh - 340px) !important;\n}\n\n.ui.form {\n    padding: 0 30px !important;\n}\n\n.ui.form>.form-container {\n    border: none !important;\n}\n\n.ui.form>.form-container>.form-content {\n    margin: 0 !important;\n}\n\n.e-control.e-dialog.form>.e-dlg-header-content {\n    padding-bottom: 0 !important;\n}\n\n.e-control.e-dialog.form>.e-dlg-content {\n    display: none !important;\n}\n\n.modal-close-icon {\n    cursor: pointer !important;\n    float: right !important;\n}\n\n.ui.modal>.header {\n    border: none !important;\n}\n\n.e-footer-content {\n    display: flex !important;\n    justify-content: flex-end !important;\n}\n\n.modal-dialog-btn {\n    background-color: var(--primaryColor) !important;\n    border: 1px solid #D3D3D3 !important;\n    border-radius: 12px !important;\n    box-shadow: none !important;\n    color: var(--primaryContrast) !important;\n}\n\n.modal-dialog-btn:hover,\n.modal-dialog-btn:focus,\n.modal-dialog-btn:active {\n    background-color: color-mix(in srgb, var(--primaryColor) 80%, black 20%) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
