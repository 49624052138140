export const handleExpandLevel = (instance, level) => {
    
  if (!instance) return;
  
  switch (level) {
    case 0:
      instance.collapseAtLevel(0);
      instance.collapseAtLevel(1);
      instance.collapseAtLevel(2);
      break;
    case 1:
      instance.expandAtLevel(0);
      instance.collapseAtLevel(1);
      instance.collapseAtLevel(2);
      break;
    case 2:
      instance.expandAtLevel(0);
      instance.expandAtLevel(1);
      instance.collapseAtLevel(2);
      break;
    case 3:
      instance.expandAtLevel(0);
      instance.expandAtLevel(1);
      instance.expandAtLevel(2);
      break;
    case 'ExpandAll':
      instance.expandAll();
      break;
    case 'CollapseAll':
      instance.collapseAll();
      break;
    default:
      break;
  }
};