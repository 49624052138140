import { useContext, useEffect } from 'react';
import { GalleryContext } from '../../context/Gallery.context';
import { runDynamicQuery } from 'src/api/query/run.dynamicQuery';
import { serviceFilePreview } from 'src/api/services/tools/service.filePreview';
import { run as runActions } from '../../../../../store/workspaces/workspaces/run';
import notAvailablePic from '../../../../../images/missing.png';
import { matchFileType } from '../services/matchFileType';


export const useFetchDataCarousel = (navData, actionsState) => {

  const onClickEventKey = navData.widgetData.events.find(event => event.type === 'onClick')?.key ?? null;

  const {
    setImageData,
  } = useContext(GalleryContext);

  useEffect(() => {
    const fetchData = async () => {
      if (navData) {
        try {
          const response = await runDynamicQuery({ ...actionsState, widgetKey: navData.widgetData.key }, navData.widgetData);
          
          const newTargetsArray = await Promise.all(response.targets.map(async (image) => {
            const filePreviewImage = await serviceFilePreview('/services/file/preview?absolutePath=' + image.image);
            const filePreviewThumbnail = await serviceFilePreview('/services/file/preview?absolutePath=' + image.thumbnail);
    
            const isImagePreviewValid = filePreviewImage && !filePreviewImage.error;
            const fileType = isImagePreviewValid ? matchFileType(image.image) : null;
    
            image.original = isImagePreviewValid
              ? `data:@file/${fileType};base64,${filePreviewImage.data}`
              : notAvailablePic;
    
            image.thumbnail = isImagePreviewValid
              ? `data:@file/${fileType};base64,${filePreviewThumbnail.data}`
              : notAvailablePic;
            image.description = null;
    
            return image;
          }));
    
          setImageData(newTargetsArray);
          
          if (onClickEventKey) {
            runActions(onClickEventKey, newTargetsArray[0]?.id, actionsState);
          }
        } catch (error) {
          console.log('Error fetching carousel data: ', error);
        }
      }
    };
  
    fetchData(); 
  }, []);
  
};
