import React, { createContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

export const ViewVideoContext = createContext(null);

export const ViewVideoProvider = ({ children, commonProps }) => {

  const viewInstance = useRef(null);

  const [absolutePath, setAbsolutePath] = useState(null);
  const [currentWorkspaceUuid, setCurrentWorkspaceUuid] = useState(null);
  const workspaces = useSelector((state: any) => state.workspaces.instances);
  
  return (
    <ViewVideoContext.Provider
      value={{
        viewInstance,
        absolutePath,
        setAbsolutePath,
        currentWorkspaceUuid,
        setCurrentWorkspaceUuid,
        workspaces,
        ...commonProps
      }}
    >
      {children}
    </ViewVideoContext.Provider>
  );
};

ViewVideoProvider.propTypes = {
  children: PropTypes.node.isRequired,
  commonProps: PropTypes.object
};
