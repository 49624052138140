import { runStaticQuery } from 'src/api/query/run.staticQuery';
import { run as runActions } from '../../../../store/workspaces/workspaces/run';
import { runWorkflow } from 'src/api/workflow/runWorkflow';
import { clearBreadcrumb } from 'src/store/workspaces/breadcrumb/actions';
import store from 'src/store/store';
import { setNavigationTreeData } from 'src/store/workspaces/navigation/actions';

export  const goHome = async() => { 
  const response = await runStaticQuery('workspace', null);
  const onLoadEvent = response.workspace.events.find((event) => event?.type === 'onLoad');

  if (onLoadEvent) {
    const key = onLoadEvent.key;
    const response = await runWorkflow(key, null, {}, null, true);
    await runActions(key, null, { ...response });
    await store.dispatch(clearBreadcrumb());
    await store.dispatch(setNavigationTreeData([]));
  }
};