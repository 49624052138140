import { ColumnDirective } from '@syncfusion/ej2-react-grids';
import { setFilterDateTimeTemplate } from '../../services/setDateTimeFilter';
import { GridViewContext } from '../context/Grid.view.context';
import { setColumnTemplateInline } from './setColumnTemplateInline';
import React, { useContext } from 'react';

export   const renderColumn = (column) => {

  const {
    navData,
    dateFormat,
    timeFormat,
    gridEditData,
    setGridEditData,
    settings,
    aggregations,
    t
  } = useContext(GridViewContext);

  const commonProps = {
    field: column.name,
    headerText: t(column.label),
    width: column.minWidth,
    minWidth: column.minWidth,
    maxWidth: column.maxWidth,
    customAttributes: column.name === 'id' ? { class: [settings.customAttributes.class, 'id-cell'] } : settings.customAttributes,
    visible: !column.hide,
    disableHtmlEncode: false,
    isPrimaryKey: column.name === 'id'
  };
  
  const typeProps = {
    boolean: {
      type: 'boolean',
      textAlign: 'Center',
      displayAsCheckBox: true,
      customAttributes: { class: [settings.customAttributes.class, 'code-cell'] }
    },
    code: {
      textAlign: 'Center',
      template: (row) => setColumnTemplateInline(row, navData),
      customAttributes: { class: [settings.customAttributes.class, 'code-cell'] }
    },
    datetime: {
      type: 'dateTime',
      filterBarTemplate: setFilterDateTimeTemplate({ dateFormat, timeFormat }, column, gridEditData, setGridEditData),
      format: { type: 'dateTime', format: `${dateFormat} ${timeFormat}` }
    },
    date: {
      type: 'date',
      filterBarTemplate: setFilterDateTimeTemplate({ dateFormat, timeFormat }, column, gridEditData, setGridEditData),
      format: { type: 'date', format: dateFormat }
    },
    float: {
      textAlign: 'Right',
      type: aggregations ? 'number' : null,
      format: aggregations ? 'N2' : null,
      filter: settings.filterMenu
    },
    default: {
      type: 'string',
      filter: settings.filterMenu
    }
  };
  
  const columnTypeProps = typeProps[column.type] || typeProps.default;
  
  return <ColumnDirective 
    key={ `field-${column.id}`}
    {...commonProps} 
    {...columnTypeProps} 
  />;
  
};