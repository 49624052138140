import { useContext, useEffect } from 'react';
import { ViewOfficeContext } from '../context/View.office.context';
import { getUserData } from 'src/api/services/user/user.get';

export const useSetUser = () => {
  const { setUser } = useContext(ViewOfficeContext);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await getUserData();
        setUser({ ...res?.data?.user });
      } catch (error) {
        console.error('Fehler beim Laden der Benutzerdaten:', error);
      }
    };

    fetchUser();
    return () => setUser(null);
  }, []);
};
