import React, { useContext } from 'react';
import MenuBar from '../../../../components/MenuBar/MenuBar';
import { WidgetProvider } from '../../WidgetContext';
import PropTypes from 'prop-types';
import { WidgetContainerStyled, WidgetContentStyled } from '../../styles';
import { ViewVideoContext } from './context/View.video.context';
import { useSetCurrentWorkspace } from './hooks/useSetCurrentWorkspace';
import { ViewContainerStyled } from '../text/styles';
import { useFetchdata } from './hooks/useFetchData';

const ViewVideo = () => {

  const {
    currentWorkspaceUuid,
    navData, 
    actionsState,
    viewInstance,
    absolutePath 
  } = useContext(ViewVideoContext);


  useFetchdata();
  useSetCurrentWorkspace();

  if (!absolutePath) return null;

  return (
    <WidgetContainerStyled>
      <WidgetProvider value={{
        widgetData: navData.widgetData,
        viewInstance: viewInstance.current,
        actionsState: actionsState,
        currentWorkspaceUuid: currentWorkspaceUuid
      }}>
        {navData.widgetData.menu ? (
          <MenuBar
            key={`menu-${navData.widgetData.menu.id}`}
            menu={navData.widgetData.menu}
            widgetTools={null}
          />
        ) : null}
        {/* <WidgetLabelStyled>{t(navData.label)} </WidgetLabelStyled> */}
        <WidgetContentStyled>
          <ViewContainerStyled css={navData.widgetData.style}>
            {absolutePath ? (
              <video 
                ref={viewInstance}
                width="100%" 
                height="auto" 
                controls
              >
                <source 
                  src={absolutePath} 
                  type="video/mp4" 
                />
      Your browser does not support HTML5 video.
              </video>
            ) : (
              <p>Kein Video verfügbar.</p>
            )}
          </ViewContainerStyled>
        </WidgetContentStyled>
      </WidgetProvider>
    </WidgetContainerStyled>
  );
};


ViewVideo.propTypes = {
  navData: PropTypes.object,
  actionsState: PropTypes.object
};

export default ViewVideo;
