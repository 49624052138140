import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { DocumentEditor } from '@onlyoffice/document-editor-react';
import { WidgetProvider } from '../../WidgetContext';
import MenuBar from '../../../MenuBar/MenuBar';
import { WidgetContainerStyled } from '../../styles';
import { ViewOfficeContext } from './context/View.office.context';
import { useSetDocument } from './hooks/useSetDocument';
import { useSetCurrentWorkspace } from './hooks/useSetCurrentWorkspace';
import { useSetUser } from './hooks/useSetUser';


const ViewOffice = () => {

  const {
    navData,
    actionsState,
    viewerInstance,
    token,
    document,
    documentType,
    callbackUrl,
    documentReady,
    setDocumentReady,
    mode,
    setViewerInstance,
    user
  } = useContext(ViewOfficeContext);

  useSetUser();
  useSetDocument();
  useSetCurrentWorkspace();


  if (!document?.url || !user) return;

  return (
    <WidgetContainerStyled>
      <WidgetProvider value={{
        instance: viewerInstance,
        widgetData: navData.widgetData,
        actionsState
      }}>
        <div style={{ visibility: documentReady ? 'visible' : 'hidden' }}>
          <MenuBar key={`menu-${navData.widgetData.menu?.key}`} menu={navData.widgetData.menu} widgetTools={null} />
        </div>
        <DocumentEditor
          id={`office-${Math.random()}`} 
          documentServerUrl={`${process.env.REACT_APP_URL}api/office/`}
          config={{
            token: token,
            document: document,
            documentType: documentType, 
            editorConfig: {
              callbackUrl: callbackUrl,
              mode: mode,
              region: 'de-DE',
              // region: `${localStorage.getItem('language')}-${localStorage.getItem('language')?.toUpperCase()}` || 'de-DE',
              user: {
                id: user.uuid,
                name: user.name || 'Gast'
              }, 
              lang: 'de-DE',
              // lang: `${localStorage.getItem('language')}-${localStorage.getItem('language')?.toUpperCase()}` || 'de-DE',    
              customization: {
                integrationMode: 'embed',
                autosave: false,
                chat: false,
                comments: true,
                compactHeader: true,
                compactToolbar: true,
                compatibleFeatures: true,
                toolbarNoTabs: true, 
                feedback: true,
                forcesave: true,
                help: true,
                hideRightMenu: true,
                hideRulers: true,
                macros: true,
                spellcheck: true,
                uiTheme: 'default-light',
                plugins: false,
                // layout: {
                //   toolbar: {   
                //     plugins: false,
                //   },
                // },
                features: {
                  
                  roles: true,
                  spellcheck: {
                    mode: true,
                    change: true,
                  },
                  tabBackground: {
                    mode: 'toolbar',
                    change: true,
                  },
                  tabStyle: {
                    mode: 'fill',
                    change: true,
                  },
                },

              },
            },
  
          }}
          events_onAppReady={(info) => {
            // console.log('%c[OnlyOffice] App Ready:', 'color: #4CAF50; font-weight: bold;', info);
            setViewerInstance(info);
          }}
          events_onError={(info) => console.error('%c[OnlyOffice] Error:', 'color: #FF5733; font-weight: bold;', info)}
          events_onDocumentReady={() => {
            // console.log('%c[OnlyOffice] Document Ready:', 'color: #2196F3; font-weight: bold;', info);
            setDocumentReady(true); // MenuBar wird erst jetzt sichtbar
          }}
          onLoadComponentError={(code, desc) => console.error('%c[OnlyOffice] Load Component Error:', 'color: #D32F2F; font-weight: bold;', `Code: ${code}`, `Description: ${desc}`)}
          // events_onDocumentStateChange={(info) => console.log('%c[OnlyOffice] Document State Change:', 'color: #FFC107; font-weight: bold;', info)}
          // events_onMetaChange={(info) => console.log('%c[OnlyOffice] Meta Change:', 'color: #9C27B0; font-weight: bold;', info)}
          // events_onInfo={(info) => console.log('%c[OnlyOffice] Info:', 'color: #009688; font-weight: bold;', info)}
          events_onWarning={(info) => console.warn('%c[OnlyOffice] Warning:', 'color: #FF9800; font-weight: bold;', info)}
          // events_onRequestSharingSettings={(info) => console.log('%c[OnlyOffice] Request Sharing Settings:', 'color: #4CAF50; font-weight: bold;', info)}
          // events_onRequestRename={(info) => console.log('%c[OnlyOffice] Request Rename:', 'color: #795548; font-weight: bold;', info)}
          // events_onMakeActionLink={(info) => console.log('%c[OnlyOffice] Make Action Link:', 'color: #607D8B; font-weight: bold;', info)}
          // events_onRequestInsertImage={(info) => console.log('%c[OnlyOffice] Request Insert Image:', 'color: #3F51B5; font-weight: bold;', info)}
          // events_onRequestSaveAs={(info) => console.log('%c[OnlyOffice] Request Save As:', 'color: #8BC34A; font-weight: bold;', info)}
          // events_onRequestMailMergeRecipients={(info) => console.log('%c[OnlyOffice] Request Mail Merge Recipients:', 'color: #CDDC39; font-weight: bold;', info)}
          // events_onRequestCompareFile={(info) => console.log('%c[OnlyOffice] Request Compare File:', 'color: #FF5722; font-weight: bold;', info)}
          // events_onRequestEditRights={(info) => console.log('%c[OnlyOffice] Request Edit Rights:', 'color: #607D8B; font-weight: bold;', info)}
          // events_onRequestHistory={(info) => console.log('%c[OnlyOffice] Request History:', 'color: #673AB7; font-weight: bold;', info)}
          // events_onRequestHistoryClose={(info) => console.log('%c[OnlyOffice] Request History Close:', 'color: #FF9800; font-weight: bold;', info)}
          // events_onRequestHistoryData={(info) => console.log('%c[OnlyOffice] Request History Data:', 'color: #4CAF50; font-weight: bold;', info)}
          // events_onRequestRestore={(info) => console.log('%c[OnlyOffice] Request Restore:', 'color: #F44336; font-weight: bold;', info)}
        />
      </WidgetProvider>
    </WidgetContainerStyled>
  );
};

ViewOffice.propTypes = {
  navData: PropTypes.object,
  actionsState: PropTypes.object,
};

export default ViewOffice;
