export const getZoomCOntrols = (setColumnWidth, navData) => {
  
  const handleZoom = (value) => setColumnWidth((prevWidth) => prevWidth + value);

  const toolbarItems = [
    {
      prefixIcon: 'e-icons e-zoom-to-fit',
      text: 'Fit',
      click: () => {
        const kanbanDiv = document.getElementById(`kanban-${navData.widgetData.key}`);
        setColumnWidth(kanbanDiv.offsetWidth / (navData.widgetData.states.length + 20));
      },
    },
    {
      prefixIcon: 'e-icons e-zoom-to-fit',
      text: 'Standard',
      click: () => setColumnWidth(300),
    },
    {
      prefixIcon: 'e-icons e-zoom-in',
      click: () => handleZoom(-10),
    },
    {
      prefixIcon: 'e-icons e-zoom-out',
      click: () => handleZoom(10),
    }
  ];

  return toolbarItems;
};
