import types from '../types';
import { runStaticQuery } from '../../../api/query/run.staticQuery';

export const setNavigationTreeData = (data: any) => ({
  type: types.GET_NAVIGATION,
  data,
});


export const setTreeData = (key: number) => async (dispatch: any) => {

  try {
    const navigationResponse = await runStaticQuery('navigation', { key });
    

    if (navigationResponse) {
      dispatch(setNavigationTreeData(navigationResponse.navigation));
    }
  } catch (error) {
    console.log('getNavigation error:', error);
  }
};

