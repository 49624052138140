import { marked } from 'marked';
import { sendPost } from 'src/api/services/chat/post.send';
import { toast } from 'src/utils/notification';

export const messageSend = async (args, workspaceState) => {
  const markedText = await marked(args.message.text);
  args.message.text = markedText;
  try {
    await sendPost({
      id: workspaceState.data.id,
      text: markedText,
      answerTo: workspaceState?.chat.posts.at(-1)?.id
    });
  } catch (error) {
    toast.error(`Error sending message: ${error.message}`);
    throw error;
  }
};