import store from '../../../../store/store';
import { toast } from '../../../../utils/notification';
import { setEventSourceHeartBeat } from '../heartbeat';
import { setUpdateWidgetData } from 'src/store/heartbeat/actions';

export const updateWidgetData = (event) => {
  try {
    const { instances } = store.getState().workspaces;
    const data = JSON.parse(event.data);

    const match = instances
      ?.flatMap(instance => instance.widgets?.widgets || [])
      .some(widget => String(widget.key) === String(data.key));

    store.dispatch(setUpdateWidgetData(match ? { ...data } : null));
  } catch (error) {
    toast.error('heartbeat (updateWidgetData)', error.message);
    setTimeout(setEventSourceHeartBeat, 5000);
  }
};
