import store from '../../../../store/store';
import { addIncomingPosts } from '../../../../store/workspaces/chat/actions';
import { toast } from '../../../../utils/notification';
import { setEventSourceHeartBeat } from '../heartbeat';

export const handleSendPost = (event) => {
  try {
    const data = JSON.parse(event.data);
    store.dispatch(addIncomingPosts({ ...data.response, chatId: data.chatId }));
    const chatState = store.getState().workspaces.instances.find(instance => instance.isActive)?.chat;

    if(!chatState?.isOpen) {
      const sound = new Audio('/sounds/get-message.mp3');
      sound.play();
    }

  } catch (error) {
    toast.error('heartbeat (sendPost)', error.message);
    setTimeout(setEventSourceHeartBeat, 5000);
  }
};