import styled from 'styled-components';

interface FormInputContainerStyledProps {
    hidden?: any;
}

export const FormInputContainerStyled = styled.div<FormInputContainerStyledProps>`
    display: ${props => (props.hidden ? 'none' : 'flex')};
    align-items: flex-start;
    margin-bottom: 10px;
    flex-wrap: wrap;

    .e-rte-container {
      border: none !important;
    }
`;

export const FormInputLabelContainerStyled = styled.label`
    margin-top: 5px;
    font-weight: bold;
    display: inline-flex;
    height: 19px;
    width: 240px;
    color: rgba(0, 0, 0, 0.3) !important;
    font-weight: 400 !important;
`;


export const ValidationLabelStyled = styled.span`
    color: red !important;
    margin-top: 5px;
`;