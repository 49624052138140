import React, { useState, useEffect, useRef } from 'react';
import fetchTreeGridData from '../services/fetchTreeGridData';
import {
  Edit,
  Group,
  Selection,
  CommandColumn,
} from '@syncfusion/ej2-react-grids';
import {
  ColumnDirective,
  ColumnsDirective,
  ContextMenu,
  ExcelExport,
  Filter,
  Inject,
  Page,
  PageSettingsModel,
  PdfExport,
  Resize,
  RowDD,
  Sort,
  TreeGridComponent,
  Toolbar,
  Aggregate,
  AggregatesDirective,
  AggregateDirective,
  AggregateColumnsDirective,
  AggregateColumnDirective,
  VirtualScroll
} from '@syncfusion/ej2-react-treegrid';
import { WidgetProvider } from '../../WidgetContext';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { WidgetContainerStyled, WidgetContentStyled, WidgetLabelStyled } from '../../styles';
import { handleExpandLevel } from '../services/handleExpandLevel';
import '../styles.css';
import { useSelector } from 'react-redux';
import MenuBar from 'src/components/MenuBar/MenuBar';
import { getToolbarControls } from '../services/toolbar.controls';
import { getSettingsLink } from '../../grid/link/settings';
import { linkElements } from './services/linkElements';
import { rowSelecting } from './services/rowSelecting';
import { getValue } from '@syncfusion/ej2-base';

const TreeGridWidgetLink = ({ navData, actionsState}) => {
  const [treeGridData, setTreeGridData] = useState(null);
  const [treeGridEditData, setTreeGridEditData] = useState(null);
  const [currentWorkspaceUuid, seCurrentWorkspaceUuid] = useState(null);
  const [initial, setInitial] = useState(true);
  const [isTreeGridReady, setIsTreeGridReady] = useState(false);

  const workspaces = useSelector((state: any) => state.workspaces.instances);
  const { t } = useTranslation();
  let selIndex = [];

  const treegrid = useRef(null);

  const widgetContext = {
    treeGridEditData: treeGridEditData,
    instance: treegrid.current,
    widgetData: navData.widgetData,
    actionsState: actionsState,
    currentWorkspaceUuid: currentWorkspaceUuid
  };

  useEffect(() => {
    const currentWorkspace = workspaces?.find((w) => w.isActive);
    seCurrentWorkspaceUuid(currentWorkspace.uuid);
  }, []);

  const filter = { type: 'CheckBox', operator: 'contains' };
  const pageOptions: PageSettingsModel = {
    pageSize: 20,
    pageSizes: ['5', '10', '20', '50']
  };

  const filterSettings: any = {
    mode: 'Immediate',
    hierarchyMode: 'Both',
    immediateModeDelay: 400,
    type: 'FilterBar',
    operator: 'contains'
  };


  useEffect(() => {
    setTreeGridData(null);
    fetchTreeGridData(setTreeGridData, { ...actionsState, widgetKey: navData.renderID }, navData.widgetData);
    return () => {
      setTreeGridData(null);
    };
  }, [navData.widgetData.dataID, navData.widgetData.timestamp]);


  const toolbarClick = (args) => {
    const level = args.item.properties.id.toString();
    handleExpandLevel(treegrid.current, level);
  };


  const dataBound = () => {

    setTimeout(() => {
      if (treegrid.current && selIndex.length && isTreeGridReady) {
        treegrid.current.selectRows(selIndex);
        selIndex = [];
      }
    }, 10);

    if(!initial) return;

    setIsTreeGridReady(true);
    handleExpandLevel(treegrid.current, navData.widgetData?.level);
    setInitial(false);
  };

  const created = () => {
    setTreeGridEditData(treeGridData.data.filter(element => element.selected));
  };

  const footerItemCounter = (args) => {
    return <span>Items: {args.count}</span>;
  };

  const onRowDataBound = (args) => {
    const data = args.data; 
    const row = args.row;

    if (getValue('selected', args.data.taskData) === true) {
      selIndex.push(parseInt(args.row.getAttribute('data-rowindex'), 0));
    }

    if (data.hasChildRecords) {
      const checkbox = row.querySelector('.e-rowcell .e-checkbox-wrapper');
      if (checkbox) {
        checkbox.style.display = 'none'; 
      }
    }
  };

  if(!treeGridData){
    return null;
  }

  const settings = getSettingsLink(navData);

  return (
    <WidgetContainerStyled>
      <WidgetProvider value={widgetContext}>
        <WidgetLabelStyled>{navData.widgetData.label !== '' ? t(navData.widgetData.label) : '\u00A0' } </WidgetLabelStyled>
        {navData.widgetData.menu && isTreeGridReady && (
          <MenuBar
            key={`menu-${navData.widgetData.menu.id}`}
            menu={navData.widgetData.menu}
            widgetTools={{
              items: getToolbarControls(treegrid.current),
              handler: null,
            }}
          />
        )}
        <WidgetContentStyled>
          <TreeGridComponent
            locale={JSON.parse(localStorage.getItem('language'))}
            key={`treegrid-${navData.widgetData.id}`}
            height={'100%'}
            rowHeight={28}
            ref={treegrid}
            dataSource={treeGridData.data}
            dataBound={dataBound}
            idMapping={treeGridData?.options?.id}
            parentIdMapping={treeGridData?.options?.parentId}
            pageSettings={pageOptions}
            allowExcelExport={true}
            allowPdfExport={true}
            allowSorting={true}
            allowFiltering={true}
            filterSettings={filterSettings}
            allowResizing={true}
            allowSelection={true}           
            enableVirtualization={true}
            enableCollapseAll={navData.widgetData.level === 0 ? true : false}
            treeColumnIndex={2}
            toolbarClick={toolbarClick}
            created={created}
            rowSelected={(args) => linkElements(treeGridData.data, setTreeGridEditData, navData, args, true, treegrid.current)}
            rowDeselected={(args) => linkElements(treeGridData.data, setTreeGridEditData, navData, args, false, treegrid.current)}
            rowSelecting={(args) => rowSelecting(treeGridData.data, setTreeGridEditData, navData, args, treegrid.current)}
            rowDataBound={onRowDataBound}
            selectionSettings={{checkboxOnly: true}}
            // rowSelecting={rowSelecting}
          >
            <ColumnsDirective>
              <ColumnDirective
                type='checkbox'
                width='30px'
                customAttributes={settings.customAttributes}
                allowResizing={false}
              />
              {navData.widgetData.columns.map((column) => {
                return (
                  <ColumnDirective
                    key={`field-${column.id}`}
                    field={column.name}
                    headerText={t(column.label)}
                    filter={filter}
                    visible={column.hide === false ? true : false}
                    disableHtmlEncode={false}
                    width={column.width}
                    minWidth={column.width}
                    // maxWidth={column.maxWidth}
                  />
                );
              })}
            </ColumnsDirective>
            <AggregatesDirective>
              <AggregateDirective showChildSummary={false}>
                <AggregateColumnsDirective>
                  <AggregateColumnDirective columnName='ID' type='count' footerTemplate={footerItemCounter} />
                </AggregateColumnsDirective>
              </AggregateDirective>
            </AggregatesDirective>
            <Inject services={[Sort, ContextMenu, Filter, Page, ExcelExport, Edit, Group, PdfExport, Selection, Resize, RowDD, Toolbar, CommandColumn, Aggregate, VirtualScroll]} />
          </TreeGridComponent>
        </WidgetContentStyled>
      </WidgetProvider>
    </WidgetContainerStyled>
  );
};

TreeGridWidgetLink.propTypes = {
  navData: PropTypes.object,
  actionsState: PropTypes.object
};

export default TreeGridWidgetLink;
