import React, { useContext} from 'react';
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  Legend,
  Category,
  Tooltip,
  DataLabel,
  Zoom,
  Crosshair,
  WaterfallSeries,
  LineSeries,
  StepLineSeries,
  StackingLineSeries,
  SplineSeries,
  RangeStepAreaSeries,
  RangeAreaSeries,
  AreaSeries,
  SplineRangeAreaSeries,
  StackingAreaSeries,
  StackingStepAreaSeries,
  StepAreaSeries,
  ColumnSeries,
  SplineAreaSeries,
  RangeColumnSeries,
  BarSeries,
  BoxAndWhiskerSeries,
  BubbleSeries,
  CandleSeries,
  HiloSeries,
  HistogramSeries,
  ParetoSeries,
  PolarSeries,
  RadarSeries,
  ScatterSeries,
  StackingBarSeries,
  StackingColumnSeries
} from '@syncfusion/ej2-react-charts';
import { WidgetContainerStyled, WidgetContentStyled } from '../styles';
import { WidgetProvider } from '../WidgetContext';
import MenuBar from '../../../components/MenuBar/MenuBar';
import { ChartContainerStyled } from './styles';
import { useFetchdata } from './hooks/useFetchData';
import { ChartContext } from './context/Chart.context';
import { useSetCurrentWorkspace } from './hooks/useSetCurrentWorkspace';
import { pointClick } from './services/pointClick';

const ChartWidget = () => {
  
  const {
    chartInstance,
    chartData,
    currentWorkspaceUuid,
    navData, 
    actionsState 
  } = useContext(ChartContext);

  
  useSetCurrentWorkspace();
  useFetchdata(navData, actionsState);
  
  
  if(!chartData) return;


  return (
    <WidgetContainerStyled>
      <WidgetProvider 
        value={{
          widgetData: navData.widgetData,
          viewInstance: chartInstance.current,
          actionsState: actionsState,
          currentWorkspaceUuid: currentWorkspaceUuid
        }}>
        {navData.widgetData.menu && (
          <MenuBar
            key={`menu-${navData.widgetData.menu.id}`}
            menu={navData.widgetData.menu}
            widgetTools={null}
          />
        )}
        <WidgetContentStyled>
          <ChartContainerStyled>
            <ChartComponent
              ref={(g) => (chartInstance.current = g)}
              height='100%'
              id={navData.widgetData.dataID}
              primaryXAxis={navData.widgetData.primaryXAxis}
              primaryYAxis={navData.widgetData.primaryYAxis}
              tooltip={navData.widgetData.tooltip}
              legendSettings={navData.widgetData.legendSettings}
              title={navData.widgetData.title}
              pointClick={(args) => pointClick(args, navData, chartData, actionsState)}
            >
              <Inject
                services={[
                  WaterfallSeries, 
                  Category, 
                  Tooltip, 
                  Zoom, 
                  Crosshair, 
                  Legend, 
                  DataLabel, 
                  LineSeries, 
                  StepLineSeries, 
                  StackingLineSeries, 
                  SplineSeries, 
                  AreaSeries, 
                  RangeAreaSeries, 
                  RangeStepAreaSeries, 
                  SplineRangeAreaSeries, 
                  StackingAreaSeries, 
                  StackingStepAreaSeries, 
                  StepAreaSeries, 
                  SplineAreaSeries, 
                  ColumnSeries, 
                  RangeColumnSeries, 
                  StackingColumnSeries, 
                  BarSeries, 
                  StackingBarSeries, 
                  ScatterSeries, 
                  BubbleSeries, 
                  PolarSeries, 
                  RadarSeries, 
                  HiloSeries, 
                  CandleSeries, 
                  BoxAndWhiskerSeries, 
                  HistogramSeries, 
                  ParetoSeries
                ]}
              />
              <SeriesCollectionDirective>
                {
                  navData.widgetData.series.map((serie, index) => {
                    return (
                      <SeriesDirective
                        key={index} 
                        dataSource={chartData[chartData.options.datasource]}
                        xName={serie.xName}
                        yName={serie.yName}
                        name={serie.name}
                        type={serie.type}
                        pointColorMapping={serie.pointColorMapping}
                      />
                    );
                  })
                }
              </SeriesCollectionDirective>
            </ChartComponent>
          </ChartContainerStyled>
        </WidgetContentStyled>
      </WidgetProvider>
    </WidgetContainerStyled>
  );
};


export default ChartWidget;