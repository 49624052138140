import { useContext, useEffect } from 'react';
import { ChatContext } from '../context/Chat.context';
import { getPostsData } from '../services/getPostsData';
import { setLastVisitAPI } from 'src/api/services/chat/setLastVisit';
import { setChatOpen, setPosts } from 'src/store/workspaces/chat/actions';
import store from 'src/store/store';

export const useFetchPosts = () => {

  const {
    workspaceState,
    navData,
    actionsState,
    setDataLoaded
  } = useContext(ChatContext);
    

  useEffect(() => {
    const fetchData = async () => {
      const chatId = workspaceState?.data?.id ?? actionsState.id;
  
      try {
        await getPostsData(workspaceState?.data, navData);
        await setLastVisitAPI({ id: chatId });
        await store.dispatch(setChatOpen(true, chatId));
        setDataLoaded(true);
      } catch (error) {
        console.error('Error loading chat data:', error);
      }
    };
  
    fetchData();
  
    return () => {
      const chatId = workspaceState?.data?.id ?? actionsState.id;
      setLastVisitAPI({ id: chatId });
      store.dispatch(setChatOpen(false, null));
      store.dispatch(setPosts([]));
    };
  }, [actionsState]);
  
};
