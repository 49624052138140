import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { withRouterHooks } from '../../../routes/router.hooks';
import { setTreeData } from '../../../store/workspaces/navigation/actions';
import { getModules, setSelectedModule } from '../../../store/workspaces/modules/actions';
import { useTranslation } from 'react-i18next';
import { run as runActions } from '../../../store/workspaces/workspaces/run';
import { clearBreadcrumb } from '../../../store/workspaces/breadcrumb/actions';
import PropTypes from 'prop-types';
import { DropDownListComponentStyled, NavigationContainerStyled } from './styles';
import './styles.css';
import store from 'src/store/store';

const ModulesDropdown = ({ workspace }) => {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();

  const { workspaces: { instances } } = store.getState();
  const activeWorkspace = instances.find(instance => instance?.isActive);

  const dropdownInstance = useRef(null);

  const fields = {
    text: 'label',
    value: 'key'
  };

  useEffect(() => {
    dispatch(getModules());
  }, []);

  const onChangeModule = (args) => {
    if (!args.value) return null;

    const key = args.itemData.events?.find((event) => event?.type === 'onLoad')?.key;

    if (key) {
      dispatch(clearBreadcrumb());
      runActions(key, null, null, null);
    }
    dispatch(setTreeData(args.value));
    dispatch(setSelectedModule({ key: args.value, label: args.itemData.label }));
  };

  if (workspace.navigation.modules.length !== 0 && workspace.navigation.modules) {
    workspace.navigation.modules.modules.forEach((element) => {
      element.label = t(element.label);
    });
  }

  if(!activeWorkspace) return;

  return (
    <NavigationContainerStyled>
      <DropDownListComponentStyled
        dataSource={workspace.navigation.modules.modules}
        fields={fields}
        ref={(scope) => {
          dropdownInstance.current = scope;
        }}
        placeholder={activeWorkspace.navigation.treeData.length === 0 || !workspace.navigation.modules?.selectedModule?.label 
          ? t(workspace.navigation.modules.placeholder) 
          : workspace.navigation.modules.selectedModule.label}
        change={onChangeModule}
        allowFiltering={true}
      />
    </NavigationContainerStyled>
  );
};

ModulesDropdown.propTypes = {
  workspace: PropTypes.object
};

export default withRouterHooks(ModulesDropdown);
