import React, { useState, useEffect, useRef } from 'react';
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent
} from '@syncfusion/ej2-react-grids';
import { getData } from './services/getDataLink';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { WidgetContainerStyled, WidgetContentStyled } from '../../../../../styles';

const LinkGrid = ({ params, tabType, setSelectedElement }) => {
  const [gridData, setGridData] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const gridInstance = useRef(null);
  const modalStates = useSelector((state: any) => state.workspaces.instances.find(instance => instance.isActive).modals);
  const { t } = useTranslation();

  const gridRenderData = {
    columns: [
      { name: 'target.ID', label: 'ID', field: 'target.ID', hide: false, minWidth: 50 },
      { name: 'target.id', label: 'id', field: 'target.id', hide: true, minWidth: 50 },
      { name: 'text', label: 'name', field: 'text', hide: false, minWidth: 150 }
    ],
    type: 'link',
    rowSelection: 'single'
  };

  useEffect(() => {
    setInputValue(modalStates.imageDialog.params.selectedText);
    getData(setGridData, params, tabType);
  }, [modalStates.imageDialog.params.selectedText]);

  useEffect(() => {
    if (tabType !== 'new') {
      getData(setGridData, params, tabType);
    }
  }, [tabType]);

  const rowSelected = (args) => {
    setSelectedElement(args.data);
  };

  const rowSelecting = () => {
    gridInstance.current.clearSelection();
  };

  if (!gridData) return null;

  return (
    <>
      {tabType === 'new' ?
        <input
          className="e-input textbox-link-text"
          type="text"
          id="textbox"
          placeholder="Text"
          onChange={(e) => setInputValue(e.target.value)}
          value={inputValue}
          style={{ width: '50%' }}
        />
        : null}
      <WidgetContainerStyled>
        <WidgetContentStyled>
          <GridComponent
            ref={gridInstance}
            height={'550px'}
            rowHeight={28}
            className={' e-link-grid'}
            dataSource={gridData}
            allowFiltering={true}
            allowSelection={true}
            rowSelected={rowSelected}
            rowSelecting={rowSelecting}
          >
            <ColumnsDirective>
              <ColumnDirective
                type='checkbox'
                width='24px'
                allowResizing={false}
              />
              {gridRenderData.columns.map((column: any) => {
                switch (column.type) {
                  case 'boolean':
                    return null;
                  case 'code':
                    return (
                      <ColumnDirective
                        key={`field-${column.id}`}
                        headerText={t(column.label)}
                        width={column.minWidth}
                        minWidth={column.minWidth}
                        maxWidth={column.maxWidth}
                        textAlign={'Center'}
                      />
                    );
                  default:
                    return (
                      <ColumnDirective
                        key={`field-${column.id}`}
                        field={column.name}
                        width={column.minWidth}
                        minWidth={column.minWidth}
                        maxWidth={column.maxWidth}
                        headerText={t(column.label)}
                        visible={!column.hide}
                        disableHtmlEncode={false}
                        isPrimaryKey={column.name === 'id'}
                        type={'string'}
                      />
                    );
                }
              })}
            </ColumnsDirective>
          </GridComponent>
        </WidgetContentStyled>
      </WidgetContainerStyled>
    </>
  );
};


LinkGrid.propTypes = {
  tabType: PropTypes.string,
  setSelectedElement: PropTypes.func,
  params: PropTypes.any
};


export default connect()(LinkGrid);
