import React, { useEffect, useRef } from 'react';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { run as runActions } from '../../store/workspaces/workspaces/run';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { toast } from '../../utils/notification';
import { FormValidator, FormValidatorModel } from '@syncfusion/ej2-inputs';
import { useSelector } from 'react-redux';
import { MenubarElementStyled } from './styles';
import store from 'src/store/store';
import { callFunctions } from 'src/api/functions/call.functions';

const MenuBarButton = ({ widget, element, handleNewTab }) => {
  const buttonInstance = useRef(null);
  const { t } = useTranslation();
  const workspaces = useSelector((state: any) => state.workspaces.instances);
  
  const handleKeyDownEvent = async(event) => {
    const activeWorkspace = workspaces?.find((w) => w.isActive);

    if(widget.currentWorkspaceUuid !== activeWorkspace.uuid) return; 

    const isFunctionKey = /^F[1-9]$|^F10$/.test(event.key);
    const isEnterKey = event.key === 'Enter';
    const eventApi = element.events?.find((eventObj) => eventObj?.type === 'onSubmit' || eventObj?.type === 'onClick');
    const { shortcut, key } = eventApi;

    if (!key) return null;

    if (
      eventApi &&
      shortcut &&
      shortcut === event.key &&
      (
        isFunctionKey ||
        isEnterKey
      )
    ) { 
      event.preventDefault();
      buttonInstance.current.focusIn();
      const response: any = await fetchDataForWidget(eventApi);
      return runActions(key, widget.widgetData.dataID, { ...widget.actionsState, data: response.data }, null);
    }
  };
  
  useEffect(() => {   
    const activeWorkspace = workspaces?.find((w) => w.isActive);
    const isActiveWorkspace = activeWorkspace?.widgets?.widgets?.some((w) => w?.key === widget?.widgetData?.key && shortcut);

    if(isActiveWorkspace){
      window.addEventListener('keydown', handleKeyDownEvent);
    }else{
      window.removeEventListener('keydown', handleKeyDownEvent);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDownEvent);
    };
  }, [workspaces, widget]);

  const fetchDataForWidget = async (eventApi) => {
    let data;
    let validate: boolean = true;
    // let files;
    switch (widget.widgetData.widget) {
      case 'Diagram':
        data = await widget.getContent();
        break;
      case 'View':
        try {
          if (widget.widgetData.type === 'edit') {
            const instance = widget.viewInstance;
        
            if (widget.widgetData.format !== 'image-editor') {
              data = {
                annotations: await instance.exportAnnotationsAsObject(),
                fields: await instance.exportFormFieldsAsObject(),
                signatures: instance.signatureCollection,
              };
            } else {
              data = await widget.getImageData();
            }
          }
        } catch (error) {
          toast.error('Error exporting form fields and annotations:', error.message);
        }
        break;
      case 'Gantt':
        data = await widget.getCurrentGanttData();
        break;
      case 'Grid':
        data = ['edit', 'link', 'sort', 'update'].includes(widget.widgetData.type) ? widget.gridEditData: null;
        break;
      case 'TreeGrid':
        data = ['link'].includes(widget.widgetData.type) ? widget.treeGridEditData: null;
        break;
      case 'Graph':

        runActions(eventApi.key, widget.widgetData.dataID, { ...widget.actionsState })
          .then(async () => {
            const functions = store.getState().workspaces.instances.find(instance => instance.isActive).data?.functions;
            if (functions) {
              await callFunctions(functions, { zoomFitNodes: widget.zoomFitNodes }, false);
            }
          });
        data = await widget.getAllNodesAndEdges();
        break;
      case 'Form':
        if (widget.widgetData.rules) {
          const options: FormValidatorModel = {
            rules: widget.widgetData.rules
          };
          const validator = new FormValidator(`#form-${widget.widgetData.key}`, options);
          if (!validator.validate()) {
            return validate = false;
          }
        }
        data = ['create', 'dialog', 'edit'].includes(widget.widgetData.type)  ? widget.formEditData: null;
        // files = widget.formEditFiles;
        break;
      case 'Scheduler':
        data = await widget.getCurrentViewEvents();
        break;
      case 'Kanban':
        // data = widget.kanbanData;
        data = [];
        break;
      case 'Report':
        // files = widget.formEditFiles;
        break;
      default:
        return null;
    }

    return { data, validate };
  };


  const handleButtonClick = async (e) => {
    const eventApi = element.events?.find((eventObj) => eventObj?.type === 'onSubmit' || eventObj?.type === 'onClick');
    const response: any = await fetchDataForWidget(eventApi);

    if (eventApi) {
      const { key, modifiers } = eventApi;

      handleNewTab(key, widget.widgetData.dataID, element.label, e, modifiers);

      if (!key) {
        toast.error('', 'Event is missing');
        return null;
      }
      if (!e?.ctrlKey && !modifiers?.ctrl && !modifiers?.alt && !modifiers?.shift && response?.validate) {
        return runActions(key, widget.widgetData.dataID, { ...widget.actionsState, data: response.data }, null);
      }
    }
  };


  const labelText = t(element.label);
  const shortcut = element.events?.find((eventObj) => eventObj?.type === 'onSubmit' || eventObj?.type === 'onClick')?.shortcut ?? null;
  const shortcutText = shortcut !== null ? ` [${shortcut}]` : '';


  return (
    <MenubarElementStyled disabled={element.disabled}>
      <ButtonComponent
        ref={buttonInstance}
        id={`button-${element.key}`}
        cssClass={`e-custom${element.class != null ? ` ${element.class}` : ''}`}
        // cssClass={'e-custom'}
        style={{ textTransform: 'none' }}
        content={labelText + shortcutText}
        disabled={element.disabled}
        // key={`menu-item-${element.key}-random:${Math.random()}`}
        type={'button'}
        iconCss={element.icon.name !== '' ? `e-icons e-${element.icon.name}` : null}
        onClick={(e) => {
          handleButtonClick(e);
        }}
      />
    </MenubarElementStyled>
  );
};

MenuBarButton.propTypes = {
  widget: PropTypes.object,
  element: PropTypes.object,
  handleNewTab: PropTypes.func
};


export default MenuBarButton;
