import { log } from 'src/utils/notification';

export const broadcastNotificationLog = (event) => {
  const data = JSON.parse(event.data); 
  const type = data?.type;
  const message = data?.message;
  const debug = data?.debug;
  const params = data?.params;

  if(debug){
    debug?.forEach(
      debug => {
        log(
          `[ ${debug.step} ]`,
          {
            params: debug.params,
            response: debug.response
          },
          debug.style
        );
      }
    );
  }

  if(message && type){
    log(message, params, type);
  }
};
