import { useContext, useEffect } from 'react';
import { ChatContext } from '../context/Chat.context';
import { setLastVisitAPI } from 'src/api/services/chat/setLastVisit';

export const useLastVisit = () => {

  const {
    matchingInstance,
    actionsState
  } = useContext(ChatContext);
    

  useEffect(() => {
    const lastVisitIntervall = async () => {
      if (matchingInstance && matchingInstance.chat.isOpen) {
        const chatId = matchingInstance?.data?.id ?? actionsState.id;
        await setLastVisitAPI({ id: chatId });
      }
    };
    const intervalId = setInterval(lastVisitIntervall, 3000);
    return () => clearInterval(intervalId);
  }, [matchingInstance]);
};
