export const getStyles = (config, style) => {
  return `
      node {
        background-color: data(backgroundColor);
        width: data(width);
        height: data(height);
        color: data(color);
        border-color: data(borderColor);
        border-width: data(borderWidth);
        border-opacity: 0.5;
        content: data(label);
        text-valign: center;
        shape: data(shape);
        text-wrap: wrap;
        text-max-width: data(width);
        font-size: ${config.style.font.size}px;
        font-family: ${config.style.font.name};
      }
  
      node.label-hidden {
        content: '';
      }
  
      node.background-hidden {
        width: 50px;
        height: 50px;
        shape: ellipse;
        background-opacity: 0;
      }
  
      node.border-hidden {
        border-opacity: 0;
      }
  
      edge {
        width: 1px;
        line-color: data(lineColor);
        target-arrow-color: data(targetArrowColor);
        target-arrow-shape: ${config.style.edge.arrow ? 'data(targetArrowShape)' : 'none'};
        curve-style: bezier;
        label: ${config.style.edge.label ? 'data(label)' : ''};
        edge-length: 50px;
      }
  
      .eh-handle {
        border-width: 12px;
      }
  
      .eh-source {
        border-width: 2px;
        border-color: red;
      }
  
      .eh-target {
        border-width: 2px;
        border-color: red;
      }
  
      .eh-preview,
      .eh-ghost-edge {
        background-color: red;
        line-color: red;
        target-arrow-color: red;
        source-arrow-color: red;
      }
  
      .eh-ghost-edge.eh-preview-active {
        opacity: 0;
      }
  
      edge[label] {
        font-size: 8px;
        text-background-color: white;
        text-background-opacity: 1;
        text-background-padding: 2px;
        text-border-color: black;
        text-border-style: solid;
        text-border-width: 0.5px;
        text-border-opacity: 1;
      }
  
      node.selected {
        border-opacity: 0.7;
        border-color: #e12b38;
        border-width: 3px;
      }
  
      node.selected.border-hidden {
        border-opacity: 0;
        border-width: 0;
      }
  
      edge.selected {
        line-opacity: 0.7;
        width: 1px;
        text-border-color: #e12b38;
        text-border-width: 1px;
        text-border-opacity: 0.7;
        line-color: #e12b38;
        target-arrow-color: #e12b38;
      }
  
      node.hover {
        border-opacity: 0.7;
        border-color: #e12b38;
        border-width: 3px;
      }
  
      node.hover.border-hidden {
        border-opacity: 0;
        border-width: 0;
      }
  
      edge.hover {
        line-opacity: 0.7;
        width: 1px;
        text-border-color: #e12b38;
        text-border-width: 1px;
        text-border-opacity: 0.7;
        line-color: #e12b38;
        target-arrow-color: #e12b38;
      }
  
      core {
        active-bg-opacity: 0;
      }

      ${style}
    `;
};
  