// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.graph-container {
    background-color: white;
    border: 1px solid !important;
    border-color: rgb(225, 220, 220) !important;
    height: calc(100% - 14px);
    border-radius: 5px !important;
    overflow: hidden;
}

.graph-content {
    margin-left: 30px !important;
    margin-right: 40px !important;
    margin-top: 3px !important;
    margin-bottom: 50px !important;
}

.cy-infoblock {
    margin-top: 3px;
    display: solid;
    background-color: white;
    border: 1px solid black;
}

.cy-infoblock:empty {
    display: none;
}

.cy-infoblock * {
    font: Arial 6pt;
}

.cy-infoblock th,
.cy.infoblock td {
    text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/components/widgets/graph/styles.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,4BAA4B;IAC5B,2CAA2C;IAC3C,yBAAyB;IACzB,6BAA6B;IAC7B,gBAAgB;AACpB;;AAEA;IACI,4BAA4B;IAC5B,6BAA6B;IAC7B,0BAA0B;IAC1B,8BAA8B;AAClC;;AAEA;IACI,eAAe;IACf,cAAc;IACd,uBAAuB;IACvB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;AACnB;;AAEA;;IAEI,gBAAgB;AACpB","sourcesContent":[".graph-container {\n    background-color: white;\n    border: 1px solid !important;\n    border-color: rgb(225, 220, 220) !important;\n    height: calc(100% - 14px);\n    border-radius: 5px !important;\n    overflow: hidden;\n}\n\n.graph-content {\n    margin-left: 30px !important;\n    margin-right: 40px !important;\n    margin-top: 3px !important;\n    margin-bottom: 50px !important;\n}\n\n.cy-infoblock {\n    margin-top: 3px;\n    display: solid;\n    background-color: white;\n    border: 1px solid black;\n}\n\n.cy-infoblock:empty {\n    display: none;\n}\n\n.cy-infoblock * {\n    font: Arial 6pt;\n}\n\n.cy-infoblock th,\n.cy.infoblock td {\n    text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
