/* eslint-disable no-case-declarations */
import React, { useContext } from 'react';
import { ColumnsDirective, GridComponent, ContextMenu, Filter, Inject, Page, Sort, ExcelExport, Resize, RowDD, Toolbar, InfiniteScroll, Aggregate } from '@syncfusion/ej2-react-grids';
import MenuBar from '../../../../components/MenuBar/MenuBar';
import { WidgetProvider } from '../../WidgetContext';
import { setCustomContextMenu } from '../services/setContextMenu';
import { contextMenuClick } from '../services/contextMenuClick';
import { onRowDrag } from './services/onRowDrag';
import PropTypes from 'prop-types';
import { WidgetContainerStyled, WidgetContentStyled, WidgetLabelStyled } from '../../styles';
import { GridContainerStyled } from '../styles';
import setClassNames from '../services/setClassNames';
import { useFetchdata } from './hooks/useFetchData';
import { useEventListener } from './hooks/useEventListener';
import { useSetCurrentWorkspace } from './hooks/useSetCurrentWorkspace';
import { GridSortContext } from './context/Grid.sort.context';
import { created } from './services/created';
import { actionComplete } from './services/actionComplete';
import { renderColumn } from './services/renderColumn';

const GridWidgetSort = () => {

  const {
    navData,
    actionsState,
    gridSortInstance,
    currentWorkspaceUuid,
    gridEditData,
    gridData,
    setGridEditData,
    dateFormat,
    timeFormat,
    t,
    settings,
    aggregations
  } = useContext(GridSortContext);

  useFetchdata();
  useEventListener();
  useSetCurrentWorkspace();


  if (!navData?.widgetData || !gridData || !timeFormat || !dateFormat) {
    return null;
  }

  return (
    <WidgetContainerStyled>
      <WidgetProvider value={
        {
          instance: gridSortInstance.current,
          gridEditData: gridEditData,
          widgetData: navData.widgetData,
          actionsState: actionsState,
          currentWorkspaceUuid: currentWorkspaceUuid
        }
      }>
        <WidgetLabelStyled>{navData.widgetData.label !== '' ? t(navData.widgetData.label) : '\u00A0' } </WidgetLabelStyled>
        {navData.widgetData.menu ? (
          <MenuBar
            key={`menu-${navData.widgetData.menu.id}`}
            menu={navData.widgetData.menu}
            widgetTools={null}
          />
        ) : null}
        <WidgetContentStyled>
          <GridContainerStyled css={navData.widgetData.style} height={navData.widgetData.height}>
            <GridComponent
              locale={JSON.parse(localStorage.getItem('language'))}
              ref={(g) => (gridSortInstance.current = g)}
              id={`grid-${navData.widgetData.key}`}
              key={`grid-${navData.widgetData.key}`}
              height={'100%'}
              rowHeight={28}
              dataSource={gridData}
              allowExcelExport={true}
              allowFiltering={true}
              allowResizing={true}
              allowPaging={navData.widgetData.allowPaging}
              enableInfiniteScrolling={!navData?.widgetData?.allowPaging}
              allowSorting={true}
              allowRowDragAndDrop={true}
              filterSettings={settings.filterSettingsMenu}
              contextMenuItems={navData ? setCustomContextMenu(navData) : null}
              pageSettings={navData?.widgetData?.allowPaging ? settings.pageSettings : settings.pageSettingsInfinite}
              created={() => created(gridSortInstance, navData)}
              rowDrop={(args) => onRowDrag(args, gridData, setGridEditData)}
              contextMenuClick={(args) => contextMenuClick(args, gridSortInstance.current, navData ? setCustomContextMenu(navData) : null)}
              rowDropSettings={settings.rowDropSettings}
              actionComplete={(args) => actionComplete(args, navData, actionsState)}
              aggregates={aggregations}
              rowDataBound={(args) => setClassNames(args)}
            >
              <ColumnsDirective>
                {navData.widgetData.columns.map(renderColumn)}
              </ColumnsDirective>
              <Inject services={[Sort, ContextMenu, Filter, Page, ExcelExport, Resize, RowDD, Toolbar, InfiniteScroll, Sort, Aggregate]} />
            </GridComponent>
          </GridContainerStyled>
        </WidgetContentStyled>
      </WidgetProvider>
    </WidgetContainerStyled>
  );
};

GridWidgetSort.propTypes = {
  navData: PropTypes.object,
  actionsState: PropTypes.object
};

export default GridWidgetSort;
