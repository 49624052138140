import { run as runActions } from '../../../../../../store/workspaces/workspaces/run';
import { service as linkService } from '../../../../View/text/service/linkService';

const regexExpUUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;


const handleClick = async (event, element, eventKey) => {
  event.preventDefault();
  const url = new URL(element.href);
  const uuid = url.pathname.slice(1);

  if (regexExpUUID.test(uuid)) {
    try {
      const response = await linkService('system', { uuid });
      runActions(eventKey, response.id, null);
    } catch (error) {
      console.log(error);
    }
  } else {
    window.open(url.href, '_blank');
  }
};


export const setDisableLinkClass = (inactive, renderData, type) => {
  const eventKey = renderData.events?.find(event => event?.type === 'onClick')?.key;

  document.querySelectorAll('a[href]').forEach((element: any) => {
    const url = new URL(element.href);
    const uuid = url.pathname.slice(1);

    if (regexExpUUID.test(uuid) || url.href) {
      element.classList.toggle('disable-link', inactive);

      if (!inactive) {
        element.addEventListener('click', event => handleClick(event, element, eventKey));
      }

      if (url.protocol === 'https:' && type === 'view') {
        element.classList.remove('disable-link');
        element.addEventListener('click', event => 
          handleClick(event, element, eventKey)
        );
      }
    }
  });
};
