import { handleExpandLevel } from './handleExpandLevel';

export const getToolbarControls = (instance) => {
  const toolbarItems = [
    {
      prefixIcon: 'e-icons e-expand',
      tooltipText: 'Expand All Nodes',
      click: () => handleExpandLevel(instance, 'ExpandAll'),
    },
    {
      prefixIcon: 'e-icons e-collapse-2',
      tooltipText: 'Collapse All Nodes',
      click: () => handleExpandLevel(instance, 'CollapseAll'),
    },
    {
      prefixIcon: 'e-icons e-plus-2',
      click: () => {
        handleExpandLevel(instance, instance.args.data.level + 1);
      },
    },
    {
      prefixIcon: 'e-icons e-intermediate-state',
      click: () => {
        handleExpandLevel(instance, instance.args.data.level - 1);
      },
    },
  ];
  
  return toolbarItems;
};
  