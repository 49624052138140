// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.e-grid .e-rowcell.customcss {
  background-color: white;
  font-size: 13px !important;
  padding: 0 10px !important;
}

.e-grid .e-headercell.customcss {
  background-color: transparent !important;
  line-height: 20px !important;
}

.e-grid .e-headercelldiv {
  line-height: 20px !important;
  color: rgba(0, 0, 0, 0.3) !important;
}

.e-grid * {
  font-size: 14px !important;
}

.disablecheckbox {
  pointer-events: none;
  opacity: 0.5;
}

.e-treegrid {
  cursor: default !important;
}

.treegrid-toolbar-button{
  margin-right: 10px !important;
}

.e-treegrid .e-headercell.customcss {
  padding: 0 10px !important;
  background-color: hsl(0, 0%, 97%);
  /* height: 35px !important; */
  cursor: pointer;
}

.e-treegrid .e-rowcell.customcss {
  background-color: white;
  cursor: pointer;
}

.e-treegrid .e-headercell.customcss {
  padding: 0 10px !important;
  background-color: hsl(0, 0%, 97%);
  /* height: 35px !important; */
  cursor: pointer;
}

.e-treegrid .e-rowcell  {
  background-color: rgb(255, 255, 255) !important;
}

.e-checkbox-wrapper > .e-check {
  background-color: white !important;
  color: black !important;
  border: 2px solid !important;
  border-radius: 2px !important;
  border-color: #757575 !important;
}  





`, "",{"version":3,"sources":["webpack://./src/components/widgets/treegrid/styles.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,0BAA0B;EAC1B,0BAA0B;AAC5B;;AAEA;EACE,wCAAwC;EACxC,4BAA4B;AAC9B;;AAEA;EACE,4BAA4B;EAC5B,oCAAoC;AACtC;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,oBAAoB;EACpB,YAAY;AACd;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,0BAA0B;EAC1B,iCAAiC;EACjC,6BAA6B;EAC7B,eAAe;AACjB;;AAEA;EACE,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,0BAA0B;EAC1B,iCAAiC;EACjC,6BAA6B;EAC7B,eAAe;AACjB;;AAEA;EACE,+CAA+C;AACjD;;AAEA;EACE,kCAAkC;EAClC,uBAAuB;EACvB,4BAA4B;EAC5B,6BAA6B;EAC7B,gCAAgC;AAClC","sourcesContent":[".e-grid .e-rowcell.customcss {\n  background-color: white;\n  font-size: 13px !important;\n  padding: 0 10px !important;\n}\n\n.e-grid .e-headercell.customcss {\n  background-color: transparent !important;\n  line-height: 20px !important;\n}\n\n.e-grid .e-headercelldiv {\n  line-height: 20px !important;\n  color: rgba(0, 0, 0, 0.3) !important;\n}\n\n.e-grid * {\n  font-size: 14px !important;\n}\n\n.disablecheckbox {\n  pointer-events: none;\n  opacity: 0.5;\n}\n\n.e-treegrid {\n  cursor: default !important;\n}\n\n.treegrid-toolbar-button{\n  margin-right: 10px !important;\n}\n\n.e-treegrid .e-headercell.customcss {\n  padding: 0 10px !important;\n  background-color: hsl(0, 0%, 97%);\n  /* height: 35px !important; */\n  cursor: pointer;\n}\n\n.e-treegrid .e-rowcell.customcss {\n  background-color: white;\n  cursor: pointer;\n}\n\n.e-treegrid .e-headercell.customcss {\n  padding: 0 10px !important;\n  background-color: hsl(0, 0%, 97%);\n  /* height: 35px !important; */\n  cursor: pointer;\n}\n\n.e-treegrid .e-rowcell  {\n  background-color: rgb(255, 255, 255) !important;\n}\n\n.e-checkbox-wrapper > .e-check {\n  background-color: white !important;\n  color: black !important;\n  border: 2px solid !important;\n  border-radius: 2px !important;\n  border-color: #757575 !important;\n}  \n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
