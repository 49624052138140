import React, { createContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

export const ChatContext = createContext(null);

export const ChatProvider = ({ children, commonProps }) => {

  const workspaceState = useSelector((state: any) => state.workspaces.instances.find(instance => instance.isActive));
  const workspaceInstances = useSelector((state: any) => state.workspaces.instances);
  const matchingInstance = workspaceInstances.find(instance => instance.isActive);
  const [dataLoaded, setDataLoaded] = useState(false);
  const chatInstance: any = useRef(null);

  const lastMessageRef = useRef(null);

  return (
    <ChatContext.Provider
      value={{
        workspaceState,
        matchingInstance,
        chatInstance,
        lastMessageRef,
        dataLoaded,
        setDataLoaded,
        ...commonProps
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

ChatProvider.propTypes = {
  children: PropTypes.node.isRequired,
  commonProps: PropTypes.object
};
