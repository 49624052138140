/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect } from 'react';
import { runDynamicQuery } from 'src/api/query/run.dynamicQuery';
import { ViewVideoContext } from '../context/View.video.context';

export const useFetchdata = () => {

  const {
    navData,
    actionsState,
    setAbsolutePath
  } = useContext(ViewVideoContext);
    

  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await runDynamicQuery({ ...actionsState, widgetKey: navData.renderID }, navData.widgetData);
        setAbsolutePath(response.files[0]?.path || '');
      } catch (error) {
        console.log(`error fetch view data: ${error}`);
      }
    };

    fetchData();
  }, [navData.widgetData.dataID, navData.widgetData.timestamp]);
};
