import React, { useContext } from 'react';
import ChatFilterInput from './components/filter/Chat.filter';
import { WidgetContainerStyled, WidgetContentStyled } from '../styles';
import { ChatUIComponent, MessageDirective, MessagesDirective } from '@syncfusion/ej2-react-interactive-chat';
import PropTypes from 'prop-types';
import { ChatContainer } from './styles';
import { ChatContext } from './context/Chat.context';
import { useLastVisit } from './hooks/useLastVisit';
import { useFetchPosts } from './hooks/useFetchPosts';
import { messageSend } from './services/messageSend';
import './styles.css';

const Chat = () => {

  const {
    navData,
    workspaceState,
    chatInstance,
    lastMessageRef,
    dataLoaded
  } = useContext(ChatContext);

  useFetchPosts();
  useLastVisit();

  if(!workspaceState?.chat.posts) return;

  return (
    <WidgetContainerStyled>
      <div className='filter-container'>
        <ChatFilterInput
          workspaceActions={workspaceState?.data}
          navData={navData}
          lastMessageRef={lastMessageRef}
          filterMode={workspaceState?.chat.filterMode}
        />
      </div>
      <WidgetContentStyled>
        <ChatContainer>
          <ChatUIComponent 
            ref={chatInstance}
            locale="de"
            timeStampFormat='dd.MM.yyyy HH:mm'
            loadOnDemand={true}
            autoScrollToBottom={true}
            showTimeBreak={true}
            messageSend={(args) => messageSend(args, workspaceState)}
            user={{
              user: JSON.parse(localStorage.getItem('name')),
              id: JSON.parse(localStorage.getItem('name'))
            }}
            headerText={workspaceState?.data.name || 'Chat'}
          >
            <MessagesDirective>
              {dataLoaded && workspaceState?.chat.posts.map((post) => {

                const createdAt = post.createdAt; 
                const timeStamp = new Date(
                  createdAt.year,
                  (createdAt.month || 1) - 1,
                  createdAt.day || 1,
                  createdAt.hour || 0,
                  createdAt.minute || 0,
                  createdAt.second || 0,
                  Math.floor((createdAt.nanosecond || 0) / 1e6)
                );

                const author = { 
                  user: post.user.name, 
                  avatarUrl: 
                  post.user.image, 
                  id: post.user.name 
                };

                return (
                  <MessageDirective 
                    key={post.id} 
                    text={post.text} 
                    author={author} 
                    timeStamp={timeStamp} 
                    timeStampFormat='dd.MM.yyyy HH:mm'
                  />
                );
              })}
            </MessagesDirective>
          </ChatUIComponent>
        </ChatContainer>
      </WidgetContentStyled>
    </WidgetContainerStyled>
  );
};

Chat.propTypes = {
  navData: PropTypes.object,
  oldWidgetViewType: PropTypes.string,
  actionsState: PropTypes.any,
};

export default Chat;
