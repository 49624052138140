import { toast } from 'src/utils/notification';

export const broadcastNotificationToast = (event) => {
  const data = JSON.parse(event.data); 
  const type = data.type;
  const message = data.message;

  if(type === 'Info'){
    toast.info(message);
  }else if(type === 'Error'){
    toast.error(message);
  }
};
