import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

export const ViewOfficeContext = createContext(null);

export const ViewOfficeProvider = ({ children, commonProps }) => {

  const { navData } = commonProps;

  const [viewerInstance, setViewerInstance]  =  useState(null);
  const [token, setToken] = useState('');
  const [document, setDocument] = useState(null);
  const [user, setUser] = useState(null);
  const [documentType, setDocumentType] = useState('');
  const [documentReady, setDocumentReady] = useState(false);
  const [mode, setMode] = useState(navData.widgetData?.type);

  const [currentWorkspaceUuid, setCurrentWorkspaceUuid] = useState(null);
  const workspaces = useSelector((state: any) => state.workspaces.instances);
  const callbackUrl = `${process.env.REACT_APP_BACKEND_URL}services/v2/file/upload${navData.widgetData?.callback?.path}`;
  

  return (
    <ViewOfficeContext.Provider
      value={{
        viewerInstance,
        token,
        setToken,
        document,
        setDocument,
        documentType,
        setDocumentType,
        currentWorkspaceUuid,
        setCurrentWorkspaceUuid,
        workspaces,
        callbackUrl,
        documentReady,
        setDocumentReady,
        mode,
        setMode,
        setViewerInstance,
        user,
        setUser,
        ...commonProps
      }}
    >
      {children}
    </ViewOfficeContext.Provider>
  );
};

ViewOfficeProvider.propTypes = {
  children: PropTypes.node.isRequired,
  commonProps: PropTypes.object
};
