import { useContext, useEffect } from 'react';
import { ViewOfficeContext } from '../context/View.office.context';
import { runDynamicQuery } from 'src/api/query/run.dynamicQuery';
import { generateOnlyOfficeToken } from 'src/utils/generateOnlyOfficeToken';
import { log } from 'src/utils/notification';


export const useSetDocument = () => {
  const {
    actionsState,
    navData,
    setDocumentType,
    setToken,
    setDocument,
    callbackUrl,
    mode
  } = useContext(ViewOfficeContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await runDynamicQuery({ ...actionsState, widgetKey: navData.renderID },navData.widgetData);
        if (!response || !Object.keys(response).length) return;

        const extensionMapping = { pdf: 'pdf', docx: 'word', doc: 'word', xlsx: 'cell', csv: 'cell', pptx: 'slide', ppt: 'slide', txt: 'word', xml: 'word'};

        const filePath = response.files[0]?.path || '';
        const fileName = response.files[0]?.name || '';
        const key = response.files[0]?.key || '';
        const fileExtension = filePath.split('.').pop();

        const documentType = extensionMapping[fileExtension] || 'word';
        const fileType = fileExtension || 'txt';
        const path = filePath.split('files/')[1];

        const url = `${process.env.REACT_APP_URL}api/v2/files/${path}`;

        // const doc = {
        //   fileType: fileType, 
        //   key: uuid,
        //   title: fileName,
        //   url: url, 
        //   permissions: {
        //     chat: true,
        //     comment: true,
        //     copy: true,
        //     deleteCommentAuthorOnly: false,
        //     download: true,
        //     edit: true,
        //     editCommentAuthorOnly: false,
        //     fillForms: false,
        //     modifyContentControl: true,
        //     modifyFilter: true,
        //     print: true,
        //     protect: true,
        //     review: false,
        //   }
        // };

        const doc = {
          fileType: fileType, 
          key: key,
          title: fileName,
          url: url, 
        };

        const docWithConfig = {
          document: {
            fileType: fileType, 
            key: key,
            title: fileName,
            url: url, 
          },
          editorConfig: {
            mode: mode,
            callbackUrl: callbackUrl
          }
        };

        const token = await generateOnlyOfficeToken(docWithConfig);
        setDocumentType(documentType);
        setToken(token);
        setDocument(doc); 
      } catch (error) {
        log( 'ONLYOFFICE Viewer fetchData', { params: { ...actionsState, widgetKey: navData.renderID }, response: { message: error.message }}, 'Error');
      }
    };
    fetchData();
    // return () => setDocument(null);
  }, [navData]);
};
