import React, { useEffect, useRef, useState } from 'react';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { getPostsData } from '../../services/getPostsData';
import { getPostsDataFiltered } from '../../services/getPostsData.filtered';
import { setFilterMode } from '../../../../../store/workspaces/chat/actions';
import store from '../../../../../store/store';
import PropTypes from 'prop-types';
import './styles.css';


const ChatFilterInput = ({
  workspaceActions,
  navData,
  filterMode
}) => {
  const textBoxInstance = useRef(null);
  const [filterValue, setFilterValue] = useState('');

  useEffect(() => {
    const keyDownEvent = (event) => {
      const value = event.target?.value;
      if (event.keyCode === 13 && value) {
        handleFilterButtonClick(value);
      }
    };

    const inputElement = document.getElementById('chat-filter-input');
    inputElement.addEventListener('keydown', keyDownEvent);

    return () => {
      inputElement.removeEventListener('keydown', keyDownEvent);
      setFilterValue('');
    };
  }, []);

  const handleFilterButtonClick = async (value) => {
    await store.dispatch(setFilterMode(true));
    await getPostsDataFiltered(workspaceActions, value.toLowerCase());
  };

  const handleClearFilter = async () => {
    if (filterMode) {
      await store.dispatch(setFilterMode(false));
      await getPostsData(workspaceActions, navData);
      await setFilterValue('');
    } else {
      await setFilterValue('');
    }
  };

  return (
    <div className='filter-content'>
      <span
        className="e-icons e-search filter-icon"
        onClick={() => handleFilterButtonClick(filterValue)}
      />
      <TextBoxComponent
        id='chat-filter-input'
        ref={textBoxInstance}
        width={170}
        className='filter-input'
        value={filterValue}
        input={(e) => {
          setFilterValue(e.value);
        }}
      />
      {filterValue !== '' || filterMode ? (
        <span
          className="e-icons e-small e-close filter-icon"
          onClick={handleClearFilter}
        />
      ) : null}
    </div>
  );
};

ChatFilterInput.propTypes = {
  workspaceActions: PropTypes.any,
  targetDivRef: PropTypes.any,
  handleScroll: PropTypes.func,
  navData: PropTypes.any,
  lastMessageRef:PropTypes.any,
  filterMode: PropTypes.bool
};
export default ChatFilterInput;
