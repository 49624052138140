// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.open-navigation-icon,
.close-navigation-icon {
  width: 20px;
  height: 20px;
  font-size: 15px;
  color: var(--basic) !important;
}

.open-navigation-icon:hover,
.close-navigation-icon:hover {
  cursor: pointer !important;
}

.open-navigation-icon,
.close-navigation-icon {
  margin: 5px 0 0 5px;
}

.open-navigation-icon {
  padding: 3px 0 0 1px !important;
}

.close-navigation-icon {
  padding: 3px 0 0 0px !important;
}

.open-navigation-icon.e-show-hide-panel::before {
  content: "\\e655" !important;
}


.close-navigation-icon.e-show-hide-panel::before {
  content: "\\e656" !important;
}

.icon {
  height: 50px;
}

.icon img {
  margin: 15px 5px 15px 5px;
  max-width: 20px;
}

.e-sidebar {
  margin-top: 45px !important;
  height: calc(100% - 45px) !important;
  overflow: hidden !important;
  background-color: var(--backgroundColor) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/modules/navigation/styles.css"],"names":[],"mappings":"AAAA;;EAEE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,8BAA8B;AAChC;;AAEA;;EAEE,0BAA0B;AAC5B;;AAEA;;EAEE,mBAAmB;AACrB;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,2BAA2B;AAC7B;;;AAGA;EACE,2BAA2B;AAC7B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,2BAA2B;EAC3B,oCAAoC;EACpC,2BAA2B;EAC3B,mDAAmD;AACrD","sourcesContent":[".open-navigation-icon,\n.close-navigation-icon {\n  width: 20px;\n  height: 20px;\n  font-size: 15px;\n  color: var(--basic) !important;\n}\n\n.open-navigation-icon:hover,\n.close-navigation-icon:hover {\n  cursor: pointer !important;\n}\n\n.open-navigation-icon,\n.close-navigation-icon {\n  margin: 5px 0 0 5px;\n}\n\n.open-navigation-icon {\n  padding: 3px 0 0 1px !important;\n}\n\n.close-navigation-icon {\n  padding: 3px 0 0 0px !important;\n}\n\n.open-navigation-icon.e-show-hide-panel::before {\n  content: \"\\e655\" !important;\n}\n\n\n.close-navigation-icon.e-show-hide-panel::before {\n  content: \"\\e656\" !important;\n}\n\n.icon {\n  height: 50px;\n}\n\n.icon img {\n  margin: 15px 5px 15px 5px;\n  max-width: 20px;\n}\n\n.e-sidebar {\n  margin-top: 45px !important;\n  height: calc(100% - 45px) !important;\n  overflow: hidden !important;\n  background-color: var(--backgroundColor) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
