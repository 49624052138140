import { useContext, useEffect } from 'react';
import { ViewOfficeContext } from '../context/View.office.context';


export const useSetCurrentWorkspace = () => {
  const {
    setCurrentWorkspaceUuid,
    workspaces
  } = useContext(ViewOfficeContext);

  useEffect(() => {
    const currentWorkspace = workspaces?.find((w) => w.isActive);
    if (currentWorkspace) {
      setCurrentWorkspaceUuid(currentWorkspace.uuid);
    }
  }, []);
};
