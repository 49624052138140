// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  /* TODO: check to form css */
  .gd-ui-header-tab::before {
    margin: -15px -10px 0 -4px;
    position: relative;
    content: "\\eb51";
    color: rgb(225, 220, 220);
  }
  .gd-ui-header-tab:hover::before,
  .gd-ui-header-tab[active="1"]::before {
    color: var(--secondaryColor);
  }

  .dynamicColumn {
    padding: 5px;
    display: inline-block;
    vertical-align: top;
    border-radius: 3px;
    height: 100%;
  }

  .main-content {
    height: calc(100% - 100px);
  }

  .main-content:first-child {
    padding-left: 10px;
  }

  .main-content:last-child {
    padding-right: 10px;
  }

  .workspace-star-icon {
    color: var(--basic) !important;
    font-size: 12px !important;
    padding-top: 0;
  }


  .policy-button {
    cursor: pointer;
    font-size: 12px;
    background-color: white !important;
    border: 1px solid #D3D3D3 !important;
    border-radius: 12px !important;
    box-shadow: none !important;
    color: var(--basic) !important;


    &.e-icons {
      padding: 4px;
      border-radius: 50%;
      font-size: 10px;
    }
  }

  .policy-button-container {
    display: flex !important;
    justify-content: center !important;
    gap: 10px !important;
    margin: 7px 10px 0px 10px;
  }`, "",{"version":3,"sources":["webpack://./src/pages/module/workspaces/styles.css"],"names":[],"mappings":"EAAE,4BAA4B;EAC5B;IACE,0BAA0B;IAC1B,kBAAkB;IAClB,gBAAgB;IAChB,yBAAyB;EAC3B;EACA;;IAEE,4BAA4B;EAC9B;;EAEA;IACE,YAAY;IACZ,qBAAqB;IACrB,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;EACd;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,8BAA8B;IAC9B,0BAA0B;IAC1B,cAAc;EAChB;;;EAGA;IACE,eAAe;IACf,eAAe;IACf,kCAAkC;IAClC,oCAAoC;IACpC,8BAA8B;IAC9B,2BAA2B;IAC3B,8BAA8B;;;IAG9B;MACE,YAAY;MACZ,kBAAkB;MAClB,eAAe;IACjB;EACF;;EAEA;IACE,wBAAwB;IACxB,kCAAkC;IAClC,oBAAoB;IACpB,yBAAyB;EAC3B","sourcesContent":["  /* TODO: check to form css */\n  .gd-ui-header-tab::before {\n    margin: -15px -10px 0 -4px;\n    position: relative;\n    content: \"\\eb51\";\n    color: rgb(225, 220, 220);\n  }\n  .gd-ui-header-tab:hover::before,\n  .gd-ui-header-tab[active=\"1\"]::before {\n    color: var(--secondaryColor);\n  }\n\n  .dynamicColumn {\n    padding: 5px;\n    display: inline-block;\n    vertical-align: top;\n    border-radius: 3px;\n    height: 100%;\n  }\n\n  .main-content {\n    height: calc(100% - 100px);\n  }\n\n  .main-content:first-child {\n    padding-left: 10px;\n  }\n\n  .main-content:last-child {\n    padding-right: 10px;\n  }\n\n  .workspace-star-icon {\n    color: var(--basic) !important;\n    font-size: 12px !important;\n    padding-top: 0;\n  }\n\n\n  .policy-button {\n    cursor: pointer;\n    font-size: 12px;\n    background-color: white !important;\n    border: 1px solid #D3D3D3 !important;\n    border-radius: 12px !important;\n    box-shadow: none !important;\n    color: var(--basic) !important;\n\n\n    &.e-icons {\n      padding: 4px;\n      border-radius: 50%;\n      font-size: 10px;\n    }\n  }\n\n  .policy-button-container {\n    display: flex !important;\n    justify-content: center !important;\n    gap: 10px !important;\n    margin: 7px 10px 0px 10px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
