import styled from 'styled-components';

export const ImageEditorContainer = styled.div`
  height: 100% !important;
  width: 100% !important;

  .e-ie-drop-area {
    display: none !important;
  }
`;
