import React, { FC, useContext } from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import { WidgetContainerStyled } from '../../styles';
import { GalleryContext } from '../context/Gallery.context';
import { onSlide } from './services/onSlide';
import { useFetchDataCarousel } from './hooks/useFetchDataCarousel';
import 'react-image-gallery/styles/css/image-gallery.css';
import './../styles.css';

const CarouselWidget: FC<any> = () => {
 
  const {
    imageData,
    navData,
    actionsState
  } = useContext(GalleryContext);

  useFetchDataCarousel(navData, actionsState);


  if (!imageData) {
    return null;
  }

  return (
    <WidgetContainerStyled>
      <ImageGallery
        items={imageData}
        onSlide={(index) => onSlide(index, navData, actionsState, imageData)}
        showPlayButton={false}
        disableThumbnailScroll={false}
        lazyLoad={true}
      />
    </WidgetContainerStyled>
  );
};

CarouselWidget.propTypes = {
  actionsState: PropTypes.object,
  navData: PropTypes.object,
  props: PropTypes.object,
  index: PropTypes.number,
  label: PropTypes.string,
  name: PropTypes.string,
  absolutePath: PropTypes.string,
  currentIndex: PropTypes.number,
};

export default CarouselWidget;
