import React, { useCallback, useContext } from 'react';
import { ImageEditorComponent } from '@syncfusion/ej2-react-image-editor';
import { WidgetContainerStyled, WidgetLabelStyled } from '../../styles';
import PropTypes from 'prop-types';
import { WidgetProvider } from '../../WidgetContext';
import MenuBar from 'src/components/MenuBar/MenuBar';
import { ImageEditorContext } from './context/ImageEditor.context';
import { useSetCurrentWorkspace } from './hooks/useSetCurrentWorkspace';
import { onCreated } from './services/onCreated';
import { ImageEditorContainer } from './styles';

export const ImageEditor = () => {
  
  const {
    imageEditorRef,
    t,
    navData, 
    actionsState,
    currentWorkspaceUuid,
    setOrginalName,
    orginalName,
    fileType,
    setFileType,
  } = useContext(ImageEditorContext);

  useSetCurrentWorkspace();

  const toolbar = ['Annotate', 'Finetune', 'Filter', 'Confirm', 'Reset', 'ZoomIn', 'ZoomOut'];

  const getImageData = useCallback(async () => {

    if (!imageEditorRef.current) return null;

    const imgData = imageEditorRef.current.getImageData();

    const canvas = document.createElement('canvas');
    canvas.width = imgData.width;
    canvas.height = imgData.height;

    const context = canvas.getContext('2d');
    context.putImageData(imgData, 0, 0);
    
    return {
      base64: canvas.toDataURL(),
      fileType,
      orginalName
    };
  }, [imageEditorRef]);


  return (
    <WidgetContainerStyled>
      <WidgetProvider value={{ 
        getImageData: getImageData, 
        actionsState,
        widgetData: navData?.widgetData, 
        viewInstance: imageEditorRef,
        currentWorkspaceUuid: currentWorkspaceUuid
      }}>
        {navData.widgetData.label && (
          <WidgetLabelStyled>{t(navData.widgetData.label)}</WidgetLabelStyled>
        )}
        {navData.widgetData.menu && (
          <MenuBar
            key={`menu-${navData.widgetData.menu.id}`}
            menu={navData.widgetData.menu}
            widgetTools={null}
          />
        )}
        <ImageEditorContainer>
          <ImageEditorComponent
            allowUndoRedo
            ref={imageEditorRef}
            toolbar={toolbar}
            created={() => onCreated(navData, actionsState, imageEditorRef, setOrginalName, setFileType)}
          />
        </ImageEditorContainer>
      </WidgetProvider>
    </WidgetContainerStyled>
  );
};

ImageEditor.propTypes = {
  navData: PropTypes.object,
  actionsState: PropTypes.object
};


export default ImageEditor;
