import { log } from './notification';
import request from '../request/request';

export const generateOnlyOfficeToken = async (document) => {
  try {
    const res = await request<Request.ErrorLog, any>(false, 'POST', 'services/generate-token', document);
    return res.data;
  } catch (error) {
    log('generateOnlyOfficeToken failed:', { response: error.message }, 'Error');
  }
};
