import store from '../../../../store/store';
import { setBadgeVisible } from '../../../../store/workspaces/chat/actions';
import { toast } from '../../../../utils/notification';

export const handleNewPostsCount = (event) => {
  try {
    if (event?.type === 'newPostsCount' && event?.data) {
      const chats = JSON.parse(event?.data);
      if (chats.length > 0) {
        // const chatState = store.getState().workspaces.instances.find(instance => instance.isActive)?.chat;
        // const totalNewCount = chats
        //   .filter(chat => chat.id !== chatState.id)
        //   .map(chat => chat.count)
        //   .reduce((prev, curr) => prev + curr, 0);
        // store.dispatch(setBadgeVisible(totalNewCount !== 0, totalNewCount));

        
        const chatState = store.getState().workspaces.instances.find(instance => instance.isActive)?.chat;

        let length = chats.length;

        if (chatState && chats.some(chat => chat.id === chatState.id)) {
          length -= 1;
        }

        // if(length !== 0){
        //   const sound = new Audio('/sounds/get-message.mp3');
        //   sound.play();
        // }

        store.dispatch(setBadgeVisible(length !== 0, length));
      }
    }
  } catch (error) {
    toast.error('heartbeat (newPostsCount)', error.message);
  }
};