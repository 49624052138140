import styled from 'styled-components';



export const ChatContainer = styled.div`
   height: 100% !important;

   .e-chat-header{
    background-color: var(--primaryColor) !important;
   } 

   .e-text {
      border-radius: 10px !important;
   }

   .e-chat-header {
      height: 35px !important;
   }

   .e-timestamp {
      border-radius: 10px !important;
      background-color: white !important;
      color: grey !important;
      font-size: 11px !important;
   }
`;

