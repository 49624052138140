import { run as runActions } from '../../../../store/workspaces/workspaces/run';
import request from 'src/request/request';

export const onDragStop = async (args, kanbanData, navData, filter ) => {

  const onMoveKey = navData.widgetData.events.find(event => event.type === 'onMove')?.key;
  if (!onMoveKey) return args.cancel = true;

  args.data.map(async (record) => {
    const params = {
      ...record,
      sequence: args.index
    };

    await runActions(onMoveKey, navData.renderID, params, null);
    
    // TODO: handle data updates when a filter is applied.
    if(filter.length) return;

    await request<any, any>(false, 'POST', 'services/widgets/update-data', { data: { key: navData.widgetData.key, params: args.data[0], index: args.dropIndex } } );
  });
};