export const linkElements = (treeGridData: any, setGridEditData, navData, args: any, link: boolean, gridLinkInstance: any) => {

  const { rowSelection } = navData.widgetData;

  if (!treeGridData || !gridLinkInstance) return null;

  if (rowSelection === 'single' && args.name === 'rowDeselected' && args.isInteracted) {
    return setGridEditData([]);
  }

  if (args.isHeaderCheckboxClicked) {
    return setGridEditData(args.name === 'rowDeselected' ? [] : treeGridData?.map(row => ({...row, selected: true})));
  }

  if (rowSelection === 'single' || !args.isInteracted) {
    return args.cancel = true;
  }


  const element = treeGridData[args.data.index];
  element.selected = link;
  treeGridData[args.data.index] = element;

  setGridEditData(prevprops => {
    if (link) {
      return [...prevprops, { ...args.data.taskData, selected: true }];
    } else {
      return prevprops.filter(item => item.id !== args.data.taskData.id);
    }
  });
};