import React from 'react';
import { Breadcrumb } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import PageItem from './Breadcrumb.section';
import { BreadcrumbItemStyled, StyledBreadcrumb } from './styles';
import { goHome } from './services/goHome';


const BreadcrumbCustom = () => {
  const workspaces = useSelector((state: any) => state.workspaces);


  const length = workspaces.instances.find(instance => instance.isActive)?.breadcrumb.historyPages.length;

  return (
    <StyledBreadcrumb length={length === 0 ? 1 : length}>
      <Breadcrumb.Section
        link
        onClick={goHome}
      >
        <BreadcrumbItemStyled>
          <div className={'e-icons e-home e-medium home-custom'} />
          <Breadcrumb.Divider icon="right chevron" />
        </BreadcrumbItemStyled>
      </Breadcrumb.Section>
      {workspaces.instances.find(instance => instance.isActive)?.breadcrumb.shiftHelper?.length >= 1 ?
        <>
          <Breadcrumb.Section> ... </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right chevron" />
        </>
        : null
      }

      {
        workspaces.instances.find(instance => instance.isActive)?.breadcrumb.historyPages?.map((params, itemIndex) => {

          const isLastItem = itemIndex ===  workspaces.instances.find(instance => instance.isActive)?.breadcrumb.historyPages?.length - 1;

          return <PageItem
            key={`page-item-${params.key} + random():${Math.random()}`}
            itemIndex={itemIndex}
            params={params}
            isLast={isLastItem}
          />;
        })
      }
    </StyledBreadcrumb>
  );
};

export default BreadcrumbCustom;
