import React, { useContext } from 'react';
import { KanbanComponent, ColumnsDirective } from '@syncfusion/ej2-react-kanban';
import { KanbanContainerStyled, KanbanMenuContainer } from './styles';
import PropTypes from 'prop-types';
// import ZoomControls from './components/Kanban.zoomControls';
import { renderCardTemplate } from './services/renderCardTemplate';
import KanbanSearchFilter from './components/filter/Kanban.filter';
import { KanbanContext } from './context/Kanban.context';
import { useFetchdata } from './hooks/useFetchData';
import { cardClick } from './services/cardClick';
import { renderColumns } from './services/rederColumn';
import { cardDoubleClick } from './services/cardDoubleClick';
import { openDialog } from './services/openDialog';
import { WidgetProvider } from '../WidgetContext';
import { useSetCurrentWorkspace } from './hooks/useSetCurrentWorkspace';
import { useUpdateData } from './hooks/useUpdateData';
import MenuBar from 'src/components/MenuBar/MenuBar';
import { getZoomCOntrols } from './components/zoom.controls';
import { onDragStop } from './services/onDragStop';

const KanbanWidget = () => {

  const {
    kanbanRef,
    kanbanData,
    columnWidth,
    setColumnWidth,
    navData,
    actionsState,
    currentWorkspaceUuid,
    filter
  } = useContext(KanbanContext);


  useFetchdata();
  useSetCurrentWorkspace();
  useUpdateData();

  if (!kanbanData || !navData) {
    return null;
  }


  return (
    <WidgetProvider value={{
      instance: kanbanRef.current,
      widgetData: navData.widgetData,
      actionsState,
      kanbanData,
      currentWorkspaceUuid
    }}>
      <div className="widget-container">
        <KanbanContainerStyled columnwidth={columnWidth}>
          <KanbanComponent
            ref={kanbanRef}
            key={`kanban-${navData.widgetData.key}`}
            id={`kanban-${navData.widgetData.key}`}
            keyField={kanbanData.options.keyField}
            dataSource={kanbanData.data}
            showEmptyColumn={true}
            cardSettings={{
              headerField: kanbanData.options.headerField,
              // contentField: kanbanData.options.contentField
              template: (args) => renderCardTemplate(args, navData, actionsState),
            }}
            dragStop={(args) => onDragStop(args, kanbanData, navData, filter)}
            cardClick={(args) => cardClick(args, navData, actionsState)}
            cardDoubleClick={(args) => cardDoubleClick(args)}
            dialogOpen={(args) => openDialog(args)}
          >
            <KanbanMenuContainer>
              <MenuBar
                key={`menu-${navData.widgetData.menu.id}`}
                menu={navData?.widgetData.menu}
                widgetTools={{
                  items: getZoomCOntrols(setColumnWidth, navData),
                  handler: null, 
                }}
              />
            </KanbanMenuContainer>
            <KanbanSearchFilter data={kanbanData.data} kanbanRef={kanbanRef} navData={navData} />
            <ColumnsDirective>
              {renderColumns(navData)}
            </ColumnsDirective>
          </KanbanComponent>
        </KanbanContainerStyled>
      </div>
    </WidgetProvider>
  );
};

KanbanWidget.propTypes = {
  navData: PropTypes.object,
  actionsState: PropTypes.any,
};

export default KanbanWidget;
