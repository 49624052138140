import React, { createContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const ImageEditorContext = createContext(null);

export const ImageEditorProvider = ({ children, commonProps }) => {
    
  const { t } = useTranslation();
  const imageEditorRef = useRef(null);
  const [currentWorkspaceUuid, setCurrentWorkspaceUuid] = useState(null);
  const [orginalName, setOrginalName] = useState('');
  const [fileType, setFileType] = useState('');


  return (
    <ImageEditorContext.Provider
      value={{
        imageEditorRef,
        t,
        currentWorkspaceUuid,
        setCurrentWorkspaceUuid,
        orginalName,
        setOrginalName,
        fileType,
        setFileType,
        ...commonProps
      }}
    >
      {children}
    </ImageEditorContext.Provider>
  );
};

ImageEditorProvider.propTypes = {
  children: PropTypes.node.isRequired,
  commonProps: PropTypes.object
};
