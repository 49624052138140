import { runDynamicQuery } from 'src/api/query/run.dynamicQuery';
import { serviceFilePreview } from 'src/api/services/tools/service.filePreview';
import { matchFileType } from 'src/components/widgets/gallery/carousel/services/matchFileType';

export  const onCreated = async (navData, actionsState, imageEditorRef, setOrginalName, setFileType) => {
  try {
    const response = await runDynamicQuery({ widgetKey: navData.widgetData.key, ...actionsState }, navData.widgetData);
  
    if (!response?.file?.absolutePath) return;
  
    const resp = await serviceFilePreview(response.file.absolutePath);
    if (!resp?.data) return;
  
    const fileType = matchFileType(response.file.absolutePath);
    setOrginalName(response.file.name);
    setFileType(response.file.fileType);
    imageEditorRef.current.open(`data:@file/${fileType};base64,${resp.data}`);
  
  } catch (error) {
    console.error('Error in created function:', error);
  }
};
  