import { useContext, useEffect } from 'react';
import { KanbanContext } from '../context/Kanban.context';
import { setUpdateWidgetData } from 'src/store/heartbeat/actions';
import store from 'src/store/store';

export const useUpdateData = () => {
  const {
    kanbanRef,
    heartbeatUpdateData, 
    navData
  } = useContext(KanbanContext);


  useEffect(() => {
    const { data } = heartbeatUpdateData;

    if (data?.key !== navData.widgetData.key) return;

    kanbanRef.current.updateCard(data.params, data.index);
    store.dispatch(setUpdateWidgetData(null));
  }, [heartbeatUpdateData]);
};
