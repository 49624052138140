import { log } from '../../../utils/notification';
import request from '../../../request/request';

export const getUserData = async () => {
  try {
    const response = await request<Request.JWT, any>(false, 'GET', 'services/get-user');
    return response;
  } catch (error) {
    log('Request (type: getUser data) ', error.message, 'Error');
  }
};
